import React from 'react';

function ClockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 8C15.5523 8 16 8.44772 16 9V16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H15C14.4477 18 14 17.5523 14 17V9C14 8.44772 14.4477 8 15 8Z"
        fill="#8437BD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4Z"
        fill="#8437BD"
      />
    </svg>
  );
}

export default ClockIcon;
