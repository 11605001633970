import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import Slider from '@components/slider/Index';
import NextButton from '@components/slider/NextButton';
import PrevButton from '@components/slider/PrevButton';
import { HOMEPAGE_TESTIMONIAL } from '@constants/homepage';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { ScrollCards } from 'src/types';
import IVCard from '@invideoio/web-shared/components/Card';
import Person from '@components/testimonials/Person';
import Stars from '@components/stars';
import ClockIcon from '@components/icons/ClockIcon';
import DiamondIcon from '@components/icons/DiamondIcon';
import ScaleIcon from '@components/icons/ScaleIcon';

interface TestimonialMakeProps {
  data: ScrollCards;
  browser: string;
  platform: PlatformType;
}

const icons: Record<number, React.FC<any>> = {
  1: ClockIcon,
  2: DiamondIcon,
  3: ScaleIcon,
};

function TestimonialMake(props: TestimonialMakeProps) {
  const { data, browser, platform } = props;

  const { t } = useTranslation('home');

  const getIcon = (index: number) => {
    const Icon = icons[index + (1 % 3)];

    return (
      <Icon className={ivclass('iv-w-8 iv-h-32 sm:iv-w-5 sm:iv-h-[20px]')} />
    );
  };

  return (
    <div
      id="iv-testimonials"
      className={ivclass(
        'iv-container iv-mx-auto xl:iv-px-20 lg:iv-px-40 md:iv-px-25 sm:iv-px-2',
        'iv-pt-200 md:iv-pt-150 sm:iv-pt-100',
      )}
    >
      <div className={ivclass('iv-grid', 'iv-mb-50 sm:iv-mb-30')}>
        <TitleH2BlockGradient heading={data.title} />
      </div>
      <div
        className={ivclass(
          'iv-flex md:iv-flex-col sm:iv-flex-col',
          'iv-bg-grey-5 iv-rounded-30 sm:iv-mx-20',
        )}
      >
        <div
          className={ivclass(
            'iv-flex-1 iv-flex iv-flex-col iv-items-start',
            'iv-p-50 sm:iv-p-25',
          )}
        >
          {data.ScrollCard &&
            data.ScrollCard.map((card, index) => {
              return (
                <div
                  key={card.id}
                  className={ivclass(
                    'iv-flex iv-justify-center iv-items-start',
                    'iv-pb-50 sm:iv-pb-20 last-of-type:iv-pb-10',
                  )}
                >
                  <div
                    className={ivclass(
                      'iv-mr-40 sm:iv-mr-20',
                      'iv-w-8 iv-h-32 sm:iv-w-5',
                    )}
                  >
                    {getIcon(index)}
                  </div>
                  <div className={ivclass('')}>
                    <h3
                      className={ivclass(
                        'iv-max-w-lg',
                        'iv-text-heading-3xs sm:iv-text-body-3xl ',
                        'iv-m-heading-3xs sm:iv-m-body-3xl',
                        'iv-text-grey-85 iv-font-bold ',
                      )}
                    >
                      {card.title}
                    </h3>
                    <div
                      className={ivclass(
                        'iv-max-w-md',
                        'iv-text-body-3xl iv-m-body-3xl sm:iv-text-body-l sm:iv-m-body-l',
                        'iv-text-grey-60 iv-font-medium',
                        'iv-pt-10 sm:iv-pt-5',
                      )}
                    >
                      {card.content}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className={ivclass('iv-flex-1 iv-overflow-x-hidden', 'iv-p-20')}>
          <Slider
            sliderWrapperClasses={
              'xl:iv-ml-40 lg:iv-ml-40 md:iv-ml-25 sm:iv-ml-20'
            }
            renderButtons={(
              scrollPrev,
              scrollNext,
              prevBtnEnabled,
              nextBtnEnabled,
            ) =>
              platform === 'mobile' || platform === 'tablet' ? (
                <React.Fragment />
              ) : (
                <React.Fragment>
                  <PrevButton
                    clickHandler={scrollPrev}
                    isEndReached={prevBtnEnabled}
                  />
                  <NextButton
                    clickHandler={scrollNext}
                    isEndReached={nextBtnEnabled}
                  />
                </React.Fragment>
              )
            }
          >
            {HOMEPAGE_TESTIMONIAL.map(
              ({ image, statement, name, source, ratings }, i) => (
                <div key={i} className="iv-relative">
                  <IVCard
                    className={ivclass(
                      'iv-bg-black-4',
                      'iv-rounded-30',
                      'iv-mr-40 md:iv-mr-25 sm:iv-mr-20',
                      'xl:iv-p-40 iv-p-35 sm:iv-p-25',
                      'xl:iv-w-[440px] iv-w-[400px] sm:iv-w-[230px]',
                      'iv-bg-grey-0 iv-flex iv-flex-col iv-justify-center iv-items-center',
                    )}
                  >
                    <Person image={image} name={name} />
                    <div
                      className={ivclass(
                        'iv-relative',
                        'xl:iv-mt-40 iv-mt-35 sm:iv-mt-[24px]',
                        'iv-text-center',
                      )}
                    >
                      <div
                        className={ivclass(
                          'xl:iv-h-[362px] iv-h-[348px] sm:iv-h-[200px]',
                          'xl:iv-mb-[25px] iv-mb-25 sm:iv-mb-25',
                          'iv-overflow-ellipsis iv-overflow-hidden',
                          'iv-text-grey-100',
                          'iv-m-heading-s sm:iv-m-heading-2xs',
                          'iv-text-heading-s md:iv-text-heading-2xs sm:iv-text-heading-4xs',
                          'iv-font-extrabold',
                        )}
                      >
                        <sup
                          className={ivclass(
                            'iv-top-0',
                            'iv-text-grey-100',
                            'iv-text-heading-s md:iv-text-heading-2xs sm:iv-text-heading-4xs',
                            'iv-font-extrabold',
                          )}
                        >
                          “
                        </sup>
                        {statement}
                        <sup
                          className={ivclass(
                            'iv-top-0',
                            'iv-text-grey-100',
                            'iv-text-heading-s md:iv-text-heading-2xs sm:iv-text-heading-4xs',
                            'iv-font-extrabold',
                          )}
                        >
                          ”
                        </sup>
                        <span
                          className={ivclass(
                            'iv-block iv-pt-20',
                            'iv-text-grey-85',
                            'xl:iv-text-body-3xl iv-text-body-2xl sm:iv-text-body-m',
                            'xl:iv-m-body-3xl iv-m-body-2xl sm:iv-m-body-m',
                            'iv-font-medium',
                            'iv-text-center',
                          )}
                        >
                          {name}
                        </span>
                      </div>
                    </div>

                    <div className={ivclass('iv-mt-10')}>
                      <div
                        className={ivclass(
                          'iv-flex',
                          'iv-justify-between',
                          'iv-items-end',
                          'iv-h-[38px]',
                        )}
                      >
                        <div
                          className={ivclass(
                            'iv-flex',
                            'iv-flex-col',
                            'iv-items-center',
                          )}
                        >
                          <span
                            className={ivclass(
                              'xl:iv-mb-8 iv-mb-10',
                              'iv-text-grey-55',
                              'xl:iv-text-body-xs iv-text-body-2xs',
                              'xl:iv-m-body-xs iv-m-body-2xs',
                              'iv-font-medium',
                              'iv-overflow-ellipsis iv-overflow-hidden',
                            )}
                          >
                            {source}
                          </span>
                          <span
                            className={ivclass(
                              'xl:iv-w-[100px] iv-w-[90px] lg:iv-mb-[-3px]',
                            )}
                          >
                            {ratings && (
                              <Stars
                                count={ratings}
                                color="--deep-blue"
                                maskColor="--testimonial-star-mask"
                                ratingClassName="iv-testimonials-ratings"
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </IVCard>
                </div>
              ),
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default TestimonialMake;
