import React from 'react';

function DiamondIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26 5.32739C8.44953 5.11888 8.71823 5 9 5H23C23.2818 5 23.5505 5.11888 23.74 5.32739L28.74 10.8283C29.0748 11.1966 29.0877 11.7551 28.7704 12.1385L16.7704 26.6378C16.5804 26.8674 16.298 27.0002 16 27.0002C15.702 27.0002 15.4196 26.8674 15.2296 26.6378L3.22962 12.1385C2.91228 11.7551 2.92523 11.1966 3.26001 10.8283L8.26 5.32739ZM8.62607 7.89813L9.57238 10.5009H6.2603L8.62607 7.89813ZM6.12569 12.5009H10.2871L13.2595 21.1205L6.12569 12.5009ZM18.7405 21.1205L25.8743 12.5009H21.7129L18.7405 21.1205ZM22.4276 10.5009H25.7397L23.3739 7.89813L22.4276 10.5009ZM21.5724 7H18.2603L20.6261 9.60279L21.5724 7ZM18.7397 10.5009L16 7.48674L13.2603 10.5009H18.7397ZM12.4026 12.5009H19.5974L16 22.9328L12.4026 12.5009ZM11.3739 9.60279L13.7397 7H10.4276L11.3739 9.60279Z"
        fill="#DC2D83"
      />
    </svg>
  );
}

export default DiamondIcon;
