import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import ImageComponent from '@components/common/ImageComponent/ImageComponent';

interface PersonProps {
  image?: string;
  video?: string;
  videoMask?: string;
  name?: string;
}

const Person = (props: PersonProps) => {
  const { image, video, videoMask, name } = props;
  return (
    <div
      className={ivclass(
        'iv-relative',
        'iv-rounded-full',
        'iv-overflow-hidden',
        'iv-h-[90px] sm:iv-h-[70px]',
        'iv-w-[90px] sm:iv-w-[70px]',
      )}
    >
      {image && (
        <ImageComponent
          elementype="next"
          src={image}
          alt="logo"
          width="100"
          height="100"
        />
      )}

      {video && (
        <VideoComponent
          autoPlay
          loop
          muted
          width="100%"
          height="100%"
          poster=""
        >
          <source src={video} />
        </VideoComponent>
      )}
      {video && videoMask && (
        <div
          className={ivclass(
            'iv-absolute',
            'iv-top-0 iv-left-0',
            'iv-h-full iv-w-full',
            'iv-bg-black-15 iv-opacity-15',
          )}
        >
          <div
            className={ivclass(
              'iv-flex',
              'iv-justify-center',
              'iv-h-full iv-w-full',
            )}
          >
            <ImageComponent
              elementype="next"
              src={videoMask}
              alt="logo"
              width="23"
              height="26"
            />
          </div>
        </div>
      )}
      {!(video || image) && (
        <div
          className={ivclass(
            'iv-flex',
            'iv-justify-center',
            'iv-items-center',
            'iv-bg-deep-blue',
            'iv-h-full iv-w-full',
            'iv-text-grey-0',
            'iv-text-heading-2xl',
            'iv-font-semibold',
          )}
        >
          {name?.charAt(0)}
        </div>
      )}
    </div>
  );
};

export default Person;
