import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

interface StarsProps {
  count: number;
  size?: string;
  color: string;
  maskColor: string;
  ratingClassName?: string;
}

const Stars = ({
  count,
  size = '--rating-star-default-size',
  color,
  maskColor,
  ratingClassName = 'iv-rating-stars',
}: StarsProps) => {
  const style = {
    '--star-rating': count,
    '--star-size': `var(${size})`,
    '--star-background': `var(${color})`,
    '--star-mask': `var(${maskColor})`,
  } as React.CSSProperties;

  return (
    <span className={ivclass('iv-flex', 'iv-justify-center', 'iv-relative')}>
      <span className={ratingClassName} style={style} />
    </span>
  );
};

export default Stars;
